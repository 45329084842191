import React from "react";
import { BalanceIndicator } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import { Radio, RadioGroup, Text } from "@fluentui/react-components";
import { useFormikContext } from "formik";

export interface IBalanceIndicatorRadioGroupProps {
  fieldName: string,
  value: BalanceIndicator,
  readOnly?: boolean,
  label?: string,
  inline?: boolean
}

export default function BalanceIndicatorRadioGroup(props: IBalanceIndicatorRadioGroupProps) {

  const {
    setFieldValue
  } = useFormikContext();

  const {
    fieldName,
    readOnly,
    value,
    label,
    inline
  } = props;

  return (
    <Flex row={inline} fullWidth>
      {
        label && <Text>{label}</Text>
      }
      <RadioGroup
        disabled={readOnly}
        layout="horizontal"
        onChange={(_, data) => setFieldValue(fieldName, data.value)}
        value={value}
        name={fieldName}
      >
        <Radio value={BalanceIndicator.Credit} label="Haben" />
        <Radio value={BalanceIndicator.Debit} label="Soll" />
      </RadioGroup>
    </Flex>
  )
}