import { Card, Checkbox, Input, Spinner, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import { CalculatorRegular } from "@fluentui/react-icons";
import React from "react";
import useNumberImport from "../../../../hooks/useNumberImport";
import useYearUtil from "../../../../hooks/useYearUtil";
import { IMappingValue, IMappingValueBase, YearlyValue } from "../../../../state/datev/numbersImport.state";
import { useMappingTree } from "../../../../state/mappingGroups/useMappingTree";
import { useAppSelector } from "../../../../state/reduxHooks";
import { CalculationElementValueSource, CalculationResultType, ILightweightMapping, IMapping, MappingCalculationElementType, Year } from "../../../../types/mapping.schema";
import { CalculationTreeStep, IMappingGroupWithMappings } from "../../../../types/mappingGroup.schema";
import { formatCurrency, formatNumber, formatPercent } from "../../../../util/formatter";
import Flex from "../../container/Flex";
import ModalDialog from "../../modal/ModalDialog";
import MappingPreviewForGroup from "../MappingPreviewForGroup";
import CalculationElementBadge from "./CalculationElementBadge";
import CalculationResultTypeSelect from "./CalculationResultTypeSelect";
import useMappedImport from "../../../../hooks/useMappedImport";

export interface ICalculateMappingTestProps {
  mapping: IMapping,
  mappingGroup: IMappingGroupWithMappings
}

export default function CalculateMappingTest(props: ICalculateMappingTestProps) {

  const {
    mapping
  } = props;

  if (!mapping || !mapping.isCalculated) return null;
  if (!mapping.calculationSteps || !mapping.calculationSteps.length) return null;

  return (
    <ModalDialog
      title={`Berechnung '${mapping.name}' überprüfen`}
      appearance="subtle"
      size="large"
      priority={10}
      icon={<CalculatorRegular />}
      renderChildrenOnlyWhenVisible
    >
      <CalculateMappingTestContent {...props} />
    </ModalDialog>
  )
}

function CalculateMappingTestContent(props: ICalculateMappingTestProps) {

  const {
    mapping,
    mappingGroup
  } = props;

  const {
    calculateMapping
  } = useNumberImport();

  const {
    mappingTree,
    loadingMappingTree
  } = useMappingTree(mappingGroup?._id)

  const getResultBase = () => ({
    current: { total: 0, diffPercent: 0, diffTotal: 0 },
    first: { total: 0, diffPercent: 0, diffTotal: 0 },
    second: { total: 0, diffPercent: 0, diffTotal: 0 },
    third: { total: 0, diffPercent: 0, diffTotal: 0 },
    fourth: { total: 0, diffPercent: 0, diffTotal: 0 }
  });

  const {
    fancyYears,
    getNextYear,
    getLastYear
  } = useYearUtil();

  const currentImport = useAppSelector(state => state.numbersImport);

  const {
    mappedResults
  } = currentImport;

  const {
    getAllMappingsWithValues
  } = useMappedImport(mappingGroup._id);

  const allMappingsWithValues = getAllMappingsWithValues();

  const [result, setResult] = React.useState<YearlyValue<IMappingValueBase>>(getResultBase());
  const [values, setValues] = React.useState<Map<string, YearlyValue<number>>>(new Map());

  const [loading, setLoading] = React.useState<boolean>(false);
  const [calculation, setCalculation] = React.useState<CalculationTreeStep[]>([]);
  const [isDifferenceCalculation, setIsDifferenceCalculation] = React.useState<boolean>(true);

  const [isBasedOnImport, setIsBasedOnImport] = React.useState<boolean>(false);
  const [valueType, setValueType] = React.useState<CalculationResultType>(mapping.resultType || CalculationResultType.Currency);

  const formatValue = (n: any, type?: CalculationResultType) => {

    const usedType = type ?? valueType;

    switch (usedType) {
      case CalculationResultType.Currency: return formatCurrency(n, { isAccountingView: true });
      case CalculationResultType.Number: return formatNumber(n, { withFraction: true });
      case CalculationResultType.Percentage: return formatPercent(n, { withFraction: true });
    }
  }

  const transformImport = (): Map<string, YearlyValue<number>> => {
    if (!mappedResults) return new Map();

    const result = new Map<string, YearlyValue<number>>();

    for (const { year } of fancyYears) {
      const resultForYear = mappedResults?.[year];

      if (!resultForYear) continue;

      const mappedResultsForGroup = resultForYear[mappingGroup._id];

      if (!mappedResultsForGroup) continue;

      for (const [id, value] of Object.entries(mappedResultsForGroup)) {
        if (!result.has(id)) result.set(id, {});
        const existing = result.get(id);

        if (existing) {
          existing[year] = value.total;
          result.set(id, existing);
        }
        else result.set(id, { [year]: value.total });
      }
    }

    return result;
  }

  React.useEffect(() => {

    if (!isBasedOnImport) return;

    setLoading(true);

    setTimeout(() => {
      try {
        let calculatedResult: YearlyValue<IMappingValueBase> = getResultBase();

        for (const { year } of fancyYears) {

          const resultForYear = mappedResults?.[year];

          if (!resultForYear) continue;

          const mappedResultsForGroup = resultForYear?.[mappingGroup._id];

          if (!mappedResultsForGroup) return;

          const r = calculateMapping(calculation, mapping, new Map(Object.entries(mappedResultsForGroup)));
          calculatedResult = calculateUpdatedResult(calculatedResult, r, year);
        }

        setResult(calculatedResult);

        const transformedInput = transformImport();
        setValues(transformedInput);
      }
      finally {
        setLoading(false);
      }
    }, 1);
  }, [isBasedOnImport, mapping, mappingTree]);

  React.useEffect(() => {
    const getCalculation = () => {
      if (!mappingTree) return [];
      if (!mappingTree.calculcationTree) return [];

      return mappingTree.calculcationTree[mapping._id];
    }

    setCalculation(getCalculation());
  }, [mappingTree]);

  const transformYearlyValuesToUsableImport = (v: Map<string, YearlyValue<number>>, year: Year): Map<string, IMappingValue> => {
    const result = new Map<string, IMappingValue>();

    for (const [id, values] of v) {
      const m = mappingTree?.regularMappings?.[id];

      if (!m) continue;

      result.set(id, { ...m, total: values[year], diffPercent: 0, diffTotal: 0, values: [] });
    }

    return result;
  }

  const getTotalForMappings = (ids: Array<string>): YearlyValue<number> => {
    const result = {
      current: 0,
      first: 0,
      second: 0,
      third: 0,
      fourth: 0
    };

    for (const id of ids) {
      const v = values.get(id);

      if (!v) continue;

      for (const y of fancyYears) {
        result[y.year] += v[y.year];
      }
    }

    return result;
  }

  const calculateUpdatedResult = (refreshThis: YearlyValue<IMappingValueBase>, calcResult: IMappingValue, year: Year): YearlyValue<IMappingValueBase> => {
    const newResult = { ...refreshThis };

    newResult[year].total = calcResult.total;

    if (year !== Year.Fourth) {
      const lastYearTotal = refreshThis[getLastYear(year)].total;
      newResult[year].diffTotal = calcResult.total - lastYearTotal;
      newResult[year].diffPercent = newResult[year].diffTotal / lastYearTotal;
    }

    if (year !== Year.Current) {
      newResult[getNextYear(year)].diffTotal = refreshThis[getNextYear(year)].total - calcResult.total;
      newResult[getNextYear(year)].diffPercent = newResult[getNextYear(year)].diffTotal / calcResult.total;
    }

    return newResult;
  }

  const getRefreshedResult = (refreshThis: YearlyValue<IMappingValueBase>, values: Map<string, YearlyValue<number>>, year: Year): YearlyValue<IMappingValueBase> => {
    const valuesForYear = transformYearlyValuesToUsableImport(values, year);
    const r = calculateMapping(calculation, mapping, valuesForYear);
    return calculateUpdatedResult(refreshThis, r, year);
  }

  const updateMappingValue = (stepMapping: ILightweightMapping, value: number, year: Year) => {

    setLoading(true);

    try {
      if (!stepMapping) return;

      const newValues = new Map(values);
      const existing = newValues.get(stepMapping._id);

      if (existing) {
        const newValue = { ...existing, [year]: value };
        newValues.set(stepMapping._id, newValue);
      }
      else newValues.set(stepMapping._id, { [year]: value });

      setValues(newValues);

      const newResult = getRefreshedResult(result, newValues, year);

      setResult(newResult);
    }
    finally {
      setLoading(false);
    }
  }

  const columnCount = 3 + fancyYears.length;

  const getMappingForId = (id: string) => {
    if (!mappingTree) return null;

    const {
      regularMappings,
      calculatedMappings
    } = mappingTree;

    return regularMappings[id] ?? calculatedMappings[id];
  }

  console.log(mappingTree);
  console.log(mapping);

  if (loadingMappingTree) return <Spinner label="Lade Berechnung..." />;
  if (!mappingTree) return <Text>Keine Berechnung gefunden</Text>;

  return (
    <>
      <Flex row justify="between" align="start" fullWidth>
        <MappingPreviewForGroup mapping={mapping} />
        <Card appearance="outline">
          <Text>Parameter</Text>
          <Flex>
            <Checkbox
              defaultChecked={isBasedOnImport}
              onChange={(_, v) => setIsBasedOnImport(!!v.checked)}
              label="Mit importierten Zahlen berechnen"
            />
            <Checkbox
              defaultChecked={isDifferenceCalculation}
              onChange={(_, v) => setIsDifferenceCalculation(!!v.checked)}
              label="Differenz zum Vorjahr anzeigen"
            />
            <CalculationResultTypeSelect inline onSave={val => setValueType(val)} value={valueType} />
          </Flex>
        </Card>
      </Flex>
      <Card className="w-100" appearance="outline">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Schritt</TableHeaderCell>
              <TableHeaderCell>Typ</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
              {
                fancyYears.map(f => <TableHeaderCell key={f.label}>{f.label}</TableHeaderCell>)
              }
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              mapping.calculationSteps.map((m, index) => {
                if (m.type === MappingCalculationElementType.Operator) return (
                  <TableRow key={`step-${index}-operator`}>
                    <TableCell>
                      <Text>{index + 1}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>Operator</Text>
                    </TableCell>
                    <TableCell colSpan={columnCount - 2}>
                      <CalculationElementBadge element={m} index={index} />
                    </TableCell>
                  </TableRow>
                )

                if (m.valueSource === CalculationElementValueSource.FixedValue) return (
                  <TableRow key={`step-${index}-fixed`}>
                    <TableCell>
                      <Text>{index + 1}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>Operand</Text>
                    </TableCell>
                    <TableCell>
                      <Text>Fixer Wert</Text>
                    </TableCell>
                    {
                      fancyYears.map(f => (
                        <TableCell key={`fixed-value-${f.label}-index-${index}`}>
                          <Text>{formatValue(m.value, CalculationResultType.Number)}</Text>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                )

                if (!m.mappings || !m.mappings.length) return null;

                const total = getTotalForMappings(m.mappings);

                return (
                  <>
                    {
                      m.mappings.map((id, mappingIndex) => {

                        const mappingForId = getMappingForId(id);

                        if (!mappingForId) return null;
                        if (m.accountFrom !== undefined && !allMappingsWithValues?.has(id)) return null;

                        return (
                          <TableRow key={`step-${index}-mapping-${mappingIndex}`}>
                            <TableCell>
                              {mappingIndex === 0 && <Text>{index + 1}</Text>}
                            </TableCell>
                            <TableCell>
                              <Flex gap={1}>
                                {
                                  mappingIndex === 0 && (
                                    <>
                                      <Text>Operand</Text>
                                      {m.accountFrom !== undefined && <CalculationElementBadge element={m} index={index} />}
                                    </>
                                  )
                                }
                              </Flex>
                            </TableCell>
                            <TableCell>
                              <TableCellLayout
                                media={!!mappingForId.isCalculated ? <CalculatorRegular /> : undefined}
                              >
                                <Flex>
                                  {mappingForId.name}
                                </Flex>
                              </TableCellLayout>
                            </TableCell>
                            {
                              fancyYears.map(f => (
                                <TableCell key={`input-${f.label}-index-${index}`}>
                                  {
                                    isBasedOnImport
                                      ? <Text>{formatValue(values?.get(id)?.[f.year], mappingForId?.resultType ?? CalculationResultType.Currency)}</Text>
                                      : (
                                        <Input
                                          style={{ width: "100%", maxWidth: "150px" }}
                                          value={values?.get(id)?.[f.year]?.toString() || "0"}
                                          contentAfter={<Text>,00</Text>}
                                          type="number"
                                          onChange={(e, v) => updateMappingValue(mappingForId, Number(v.value), f.year)}
                                        />
                                      )
                                  }
                                </TableCell>
                              ))
                            }
                          </TableRow>
                        )
                      })
                    }
                    {
                      m.mappings.length > 1 && (
                        <TableRow key={`step-${index}-total`}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell><Text>=</Text></TableCell>
                          {
                            fancyYears.map(f => <TableCell key={`total-${f.label}`}>{formatValue(total[f.year])}</TableCell>)
                          }
                        </TableRow>
                      )
                    }
                  </>
                )
              })
            }
            <TableRow>
              <TableCell colSpan={columnCount}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}><Text weight="bold">Ergebnis</Text></TableCell>
              {
                fancyYears.map(f => <TableCell key={f.label + "-result"}>{f.label}</TableCell>)
              }
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}><Text>Wert</Text></TableCell>
              {
                fancyYears.map(f => <TableCell key={f.label + "-result"}>{formatValue(result[f.year]?.total)}</TableCell>)
              }
            </TableRow>
            {
              isDifferenceCalculation && (
                <>
                  <TableRow>
                    <TableCell colSpan={3}><Text>Delta</Text></TableCell>
                    {
                      fancyYears.map(f => <TableCell key={f.label + "-difference"}>{formatValue(result[f.year]?.diffTotal)}</TableCell>)
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}><Text>Delta in %</Text></TableCell>
                    {
                      fancyYears.map(f => <TableCell key={f.label + "-difference-percent"}>{formatPercent(result[f.year]?.diffPercent, { withFraction: true })}</TableCell>)
                    }
                  </TableRow>
                </>
              )
            }
          </TableBody>
        </Table>
      </Card>
    </>
  )

}