import { configureStore } from "@reduxjs/toolkit";
import ColorModeSlice from "./color/color.state";
import NumbersImportSlice from "./datev/numbersImport.state";
import LocalSlice from "./local.state";
import ModalSlice from "./modal/modal.state";

const store = configureStore({
  reducer: {
    modal: ModalSlice,
    local: LocalSlice,
    numbersImport: NumbersImportSlice,
    colorMode: ColorModeSlice
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;