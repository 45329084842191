import React from "react";
import { IMapping } from "../../../types/mapping.schema";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import useApi from "../../../api/useApi";
import CustomButton from "../button/CustomButton";
import { DeleteRegular } from "@fluentui/react-icons";
import { useUser } from "../../../state/user/useUser";
import { IMappingGroup } from "../../../types/mappingGroup.schema";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import { useMappingTemplates } from "../../../state/mappings/useMappingTemplates";

export interface IDeleteMappingButtonBase {
  afterDelete?: () => Promise<any>
}

export interface IDeleteMappingButtonProps extends IDeleteMappingButtonBase {
  mapping: IMapping,
  mappingGroup: IMappingGroup
}

export default function DeleteMappingButton(props: IDeleteMappingButtonProps) {
  const {
    mapping,
    mappingGroup,
    afterDelete
  } = props;

  const {
    user
  } = useUser();

  const {
    isNarrower
  } = useResponsive(Breakpoint.Mobile);

  const {
    reloadMappingGroups
  } = useMappingGroups();

  const {
    mappingsDelete
  } = useApi();

  const clickHandler = async () => {
    const res = await mappingsDelete(mapping._id);
    if (!res || !res.success) return;
    await reloadMappingGroups();
    if (afterDelete) await afterDelete();
  }

  if (mappingGroup.isGlobalPreset && !user.isGlobalAdmin) return null;

  return (
    <CustomButton
      text={!isNarrower && "Entfernen"}
      onClick={clickHandler}
      color="danger"
      icon={<DeleteRegular />}
      secondStepQuestion="Sicher?"
    />
  )
}