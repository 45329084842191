import React from "react";
import { IMappingValue, MappedNumberImport, YearlyValue } from "../state/datev/numbersImport.state";
import { useMappingTree } from "../state/mappingGroups/useMappingTree";
import { useAppSelector } from "../state/reduxHooks";
import { ILightweightMapping, IMapping, Operator, Year } from "../types/mapping.schema";
import { CalculationTreeStep } from "../types/mappingGroup.schema";
import useCachedImport from "./useCachedImport";

export interface IValueColumns<T = string> {
  accountNumber: T | Array<T>,
  accountName: T | Array<T>,
  startCredit: T | Array<T>,
  startDebit: T | Array<T>,
  movementCredit: T | Array<T>,
  movementDebit: T | Array<T>,
  endCredit: T | Array<T>,
  endDebit: T | Array<T>
}

export interface IAccountValue {
  accountNumber: number,
  accountName: string,
  startCredit: number,
  startDebit: number,
  movementCredit: number,
  movementDebit: number,
  endCredit: number,
  endDebit: number
}

export enum ExternalDataSource {
  Datev = "datev",
}

export type ExternalDataSourceColumnMap<T> = { [key in ExternalDataSource]: T };

export const NumberSourceKeys: ExternalDataSourceColumnMap<IValueColumns> = {
  datev: {
    accountNumber: ["Konto", "Kontonummer"],
    accountName: "Kontenbeschriftung",
    startCredit: "EbWertSoll",
    startDebit: "EbWertHaben",
    movementCredit: "JvzSoll",
    movementDebit: "JvzHaben",
    endCredit: "SaldoSoll",
    endDebit: "SaldoHaben"
  }
}

export enum NumberImportStep {
  Import = "import",
  Map = "map"
}

export interface IAccountMapEntry {
  groupId: string,
  mappingId: string,
  mapping: IMapping
}

export type AccountMap = Map<number, { [key: string]: IAccountMapEntry }>;

export interface ISortedMappings {
  mappingsForAccounts: AccountMap,
  calculatedMappings: Array<IMapping>
}

export interface ICalculationElementResult {
  isOperand: boolean,
  operator: Operator,
  operand: number,
}

export default function useNumberImport(source: ExternalDataSource = ExternalDataSource.Datev, mappingGroupId: string = "") {

  const [loading, setLoading] = React.useState<boolean>(false);

  const currentImport = useAppSelector(state => state.numbersImport);

  const {
    rawData,
    mappedResults,
    hasDifferingAccountNumberLength,
    accountNumberLength
  } = currentImport;

  const {
    partialCacheImport
  } = useCachedImport();

  const {
    loadingMappingTree,
    mappingTree
  } = useMappingTree(mappingGroupId);

  const calculateMapping = (steps: Array<CalculationTreeStep>, mapping: ILightweightMapping, valuesForMappings: Map<string, IMappingValue>): IMappingValue => {

    const result: IMappingValue = {
      ...mapping,
      diffPercent: 0,
      diffTotal: 0,
      total: 0,
      values: []
    };

    if (!steps || !steps.length) return result;

    const items: Array<number> = [];

    const calculate = (op: Operator, a: number, b: number): number => {
      try {
        switch (op) {
          case Operator.Add: return a + b;
          case Operator.Subtract: return a - b;
          case Operator.Multiply: return a * b;
          case Operator.Divide: return a / b;
        }
      }
      catch { }

      return 0;
    }

    let formula = "";

    for (const step of steps) {
      if (typeof step === "string") {
        const right = items.pop();
        const left = items.pop();

        formula += `(${left} ${step} ${right})`;
        const result = calculate(step as Operator, left ?? 0, right ?? 0);

        items.push(result);
      }
      else if (Array.isArray(step)) {
        let stepResult = 0;

        for (const m of step) {

          if (typeof m === "string") {
            const x = valuesForMappings?.get(m);

            if (!x) continue;
            if (isNaN(x.total)) continue;

            stepResult += x.total;
          }
          else stepResult += m;
        }

        items.push(stepResult);
      }
    }

    if (!items.length) return result;

    result.values = [{ accountName: "Formula: " + formula, accountNumber: 0, endCredit: 0, endDebit: 0, movementCredit: 0, movementDebit: 0, startCredit: 0, startDebit: 0 }]
    result.total = items[0];

    return result;
  }

  const calculateResultsForMappings = async (currentYearValues: Array<IAccountValue>, lastYearValues?: MappedNumberImport): Promise<MappedNumberImport> => await new Promise((resolve) => setTimeout(() => {
    const result: MappedNumberImport = {};
    const resultMap = new Map<string, Map<string, IMappingValue>>();

    try {

      if (loadingMappingTree) return resolve(result);
      if (!currentYearValues || !currentYearValues.length) return resolve(result);

      const {
        calculcationTree,
        calculatedMappings,
        calculationOrder,
        mappingsForAccounts,
        regularMappings
      } = mappingTree;

      if (!mappingsForAccounts) return result;

      currentYearValues.forEach(a => {
        const mappings = mappingsForAccounts[a.accountNumber];

        if (!mappings) return;

        const matchingMappings = Object.values(mappings);

        if (!matchingMappings?.length) return;

        let existingGroup = resultMap.get(mappingGroupId);

        if (!existingGroup) existingGroup = new Map<string, IMappingValue>();

        for (const m of matchingMappings) {

          let e = existingGroup.get(m);

          if (!e) {
            const r = regularMappings[m];
            if (!r) continue;
            e = {
              ...r,
              total: 0,
              diffPercent: 0,
              diffTotal: 0,
              values: []
            };
          }

          e.total += (a.endCredit - a.endDebit);
          e.values.push(a);

          existingGroup.set(m, e);
        }

        resultMap.set(mappingGroupId, existingGroup)
      });

      for (const item of calculationOrder) {
        const valuesForGroup = resultMap.get(mappingGroupId);
        const calculationSteps = calculcationTree[item];
        const mapping = calculatedMappings[item];

        if (!mapping) continue;
        if (!valuesForGroup) continue;
        if (!calculationSteps || !calculationSteps.length) continue;

        const calculationResult = calculateMapping(calculationSteps, mapping, valuesForGroup);

        if (!calculationResult) continue;

        if (lastYearValues) {
          const mappingsForLastYer = lastYearValues[mappingGroupId];
          if (mappingsForLastYer) {
            const lastYearMapping = mappingsForLastYer[item];
            if (lastYearMapping) {
              calculationResult.diffTotal = calculationResult.total - lastYearMapping.total;
              calculationResult.diffPercent = calculationResult.diffTotal / lastYearMapping.total;
            }
          }
        }

        let existingGroup = resultMap.get(mappingGroupId);

        if (!existingGroup) existingGroup = new Map<string, IMappingValue>();

        existingGroup.set(item, calculationResult);

        resultMap.set(mappingGroupId, existingGroup);
      }


      for (const [groupId, group] of resultMap) {
        result[groupId] = Object.fromEntries(group);
      }
    }
    catch (err) { console.log(err); }

    return resolve(result);
  }, 1));

  const calculateMappedNumbers = async (data: YearlyValue<Array<IAccountValue>>): Promise<YearlyValue<MappedNumberImport>> => await new Promise((res) => setTimeout(async () => {
    setLoading(true);

    const result: YearlyValue<MappedNumberImport> = {
      current: {},
      first: {},
      fourth: {},
      second: {},
      third: {}
    };

    if (!data) return res(result);

    if (data.fourth) result.fourth = await calculateResultsForMappings(data.fourth);
    if (data.third) result.third = await calculateResultsForMappings(data.third, result.fourth);
    if (data.second) result.second = await calculateResultsForMappings(data.second, result.third);
    if (data.first) result.first = await calculateResultsForMappings(data.first, result.second);
    if (data.current) result.current = await calculateResultsForMappings(data.current, result.first);

    setLoading(false);

    return res(result);
  }, 1));

  const getValue = (d: any, key: string | Array<string>): string => {
    if (typeof key === "string") return d[key];

    for (const k of key) {
      const v = d[k];
      if (v) return v;
    }

    return "";
  }

  const getAccountNumber = (d: any, key: string | Array<string>): number => {
    const value = getValue(d, key);
    const account = hasDifferingAccountNumberLength && accountNumberLength ? value.substring(0, accountNumberLength) : value;
    return parseInt(account);
  }

  const getImportedDataFromCsv = (data: Array<any>): Array<IAccountValue> => {
    setLoading(true);

    try {
      if (!data?.length) return [];

      const result = new Map<number, IAccountValue>();
      const keys = NumberSourceKeys[source];


      data.forEach(d => {
        
        const accountNumber = getAccountNumber(d, keys.accountNumber);
        const accountName = getValue(d, keys.accountName);
        const startCredit = parseFloat(getValue(d, keys.startCredit));
        const startDebit = parseFloat(getValue(d, keys.startDebit));
        const movementCredit = parseFloat(getValue(d, keys.movementCredit));
        const movementDebit = parseFloat(getValue(d, keys.movementDebit));
        const endCredit = parseFloat(getValue(d, keys.endCredit));
        const endDebit = parseFloat(getValue(d, keys.endDebit));

        const existing = result.get(accountNumber);

        if (existing) {
          existing.startCredit += startCredit;
          existing.startDebit += startDebit;
          existing.movementCredit += movementCredit;
          existing.movementDebit += movementDebit;
          existing.endCredit += endCredit;
          existing.endDebit += endDebit;
          result.set(accountNumber, existing);
          return;
        }

        result.set(accountNumber, {
          accountNumber,
          accountName,
          startCredit,
          startDebit,
          movementCredit,
          movementDebit,
          endCredit,
          endDebit
        });
      });

      return Array.from(result.values());
    }
    catch { }
    finally {
      setLoading(false);
    }

    return [];
  }

  const remapNumbers = async () => {
    setLoading(true);
    const result = await calculateMappedNumbers(rawData);
    partialCacheImport("mappedResults", result);
    setLoading(false);
  }

  return {
    loading: loading || loadingMappingTree,
    calculateMapping,
    calculateMappedNumbers,
    getImportedDataFromCsv,
    remapNumbers
  }
}