import { Spinner, Text } from "@fluentui/react-components";
import React from "react";
import { useMappingGroups } from "../../../../state/mappingGroups/useMappingGroups";
import Flex from "../../container/Flex";
import MappingGroup from "./MappingGroup";

export default function MappingList() {

  const {
    loadingMappingGroups,
    mappingGroups
  } = useMappingGroups();

  return (
    <Flex fullWidth>
      {
        loadingMappingGroups
          ? <Spinner />
          : (
            mappingGroups && !!mappingGroups.length
              ? mappingGroups.map(m => <MappingGroup key={m._id} mappingGroup={m} />)
              : <Text>Keine Kontenrahmen</Text>
          )
      }
    </Flex>

  )
}