import React from "react";
import { INumbersImportState, replaceImportedNumbers, setCurrentYear } from "../state/datev/numbersImport.state";
import { useAppDispatch } from "../state/reduxHooks";
import usePersistance, { StorageKey } from "./usePersistance";

export default function useCachedImport() {

  const dispatch = useAppDispatch();

  const {
    removePersistedValue,
    updatePersistedValue,
    value
  } = usePersistance<INumbersImportState>(StorageKey.DatevUpload, true, true);

  React.useEffect(() => {
    if (!value) return;
    dispatch(replaceImportedNumbers(value));
    if (!value.currentYear) dispatch(setCurrentYear(new Date().getFullYear()));
  }, [])

  const partialCacheImport = (key: keyof INumbersImportState, data: any) => {
    const updatedValue = { ...value, [key]: data };
    dispatch(replaceImportedNumbers(updatedValue));
    updatePersistedValue(updatedValue);
  }

  return {
    cacheImport: async (state: INumbersImportState) => await new Promise((resolve) => resolve(updatePersistedValue(state))),
    partialCacheImport,
    clearImport: () => removePersistedValue()
  }
}