import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../components/menu/Sidebar";
import MappingGroupList from "../components/mappings/groups/MappingGroupList";
import UpdateMappingGroupForm from "../components/mappings/groups/UpdateMappingGroupForm";
import { Card, Tab, TabList, Text, Tooltip } from "@fluentui/react-components";
import MappingTemplatesList from "../components/mappings/templates/MappingTemplatesList";
import UpdateMappingForm from "../components/mappings/UpdateMappingsForm";
import ModalDialog from "../components/modal/ModalDialog";
import UpdateEntityTypeForm from "../components/entityTypes/UpdateEntityTypeForm";
import Flex from "../components/container/Flex";
import EntityTypeList from "../components/entityTypes/EntityTypeList";
import { BuildingBankRegular, InfoRegular } from "@fluentui/react-icons";
import { useUser } from "../../state/user/useUser";
import CreateTemplateMappingForm from "../components/mappings/templates/CreateTemplateMappingForm";

export enum MappingsOverviewTab {
  Mappings = "mappings",
  Templates = "templates"
}

export default function Mappings() {

  const [tab, setTab] = React.useState<MappingsOverviewTab>(MappingsOverviewTab.Mappings);

  const {
    user
  } = useUser();


  return (
    <Page
      title={AppRoutes.Mappings.label}
      action={
        (tab === MappingsOverviewTab.Mappings || !user.isGlobalAdmin)
          ? <UpdateMappingGroupForm />
          : (
            <Flex row>
              <ModalDialog
                text="Firmentypen verwalten"
                icon={<BuildingBankRegular />}
                title="Firmentypen"
                abortButtonText="Schließen"
                action={close => <UpdateEntityTypeForm />}
              >
                <EntityTypeList />
              </ModalDialog>
              <CreateTemplateMappingForm />
            </Flex>
          )
      }
    >
      {
        user.isGlobalAdmin && (
          <TabList
            selectedValue={tab}
            onTabSelect={(_, data) => setTab(data.value as MappingsOverviewTab)}
          >
            <Tab value={MappingsOverviewTab.Mappings}>Kontenrahmen</Tab>
            <Tab
              value={MappingsOverviewTab.Templates}
              icon={(
                <Tooltip
                  relationship="description"
                  content={(
                    <Flex>
                      <Text weight="bold">Information</Text>
                      <Text>
                        Vorlagen können verwendet werden, um gleichartige Kontengruppen in verschiedenen Kontenrahmen zu erstellen.<br />
                        Vorausgesetzt, Kontenrahmen 1 und 2 haben beide eine Kontengruppe mit derselben Funktion, aber anderen Konten, kann so eine zusammenfassende Gruppe als Vorlage erstellt werden.<br />
                        Diese kann dann in einem Diagramm verwendet werden, welches dann automatisch mit beiden Kontenrahmen kompatibel ist.<br /><br />
                        So sparen Sie effektiv Zeit und vermeiden Fehler. Gleichzeitig können Sie so für ein Diagramm die Vorlagengruppe als Nummernquelle hinterlegen. Das Diagramm ist dann automatisch mit der Kontenrahmen-Implementation dieser Vorlage kompatibel, ohne dass Sie das explizit einstellen müssen.
                      </Text>
                    </Flex>
                  )}

                >
                  <InfoRegular />
                </Tooltip>
              )}
            >
              Vorlagen
            </Tab>
          </TabList>
        )
      }
      {
        (tab === MappingsOverviewTab.Mappings || !user.isGlobalAdmin)
          ? <MappingGroupList />
          : <MappingTemplatesList />
      }
    </Page>
  )
}