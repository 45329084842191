import { IMappingValue } from "../state/datev/numbersImport.state";
import { useMappingGroup } from "../state/mappingGroups/useMappingGroup";
import { useAppSelector } from "../state/reduxHooks";
import { Year } from "../types/mapping.schema";

export default function useMappedImport(mappingGroupId: string) {

  const currentImport = useAppSelector(state => state.numbersImport);

  const {
    loadingMappingGroup
  } = useMappingGroup(mappingGroupId);

  const {
    mappedResults
  } = currentImport;

  const getAllMappingsWithValues = (): Set<string> => {
    if (loadingMappingGroup) return null;
    if (!mappedResults) return null;

    const allMappings = Object.values(mappedResults);

    const result = new Set<string>();

    allMappings.forEach((yearlyValue) => {
      const forGroup = yearlyValue?.[mappingGroupId];

      if (!forGroup) return;

      const mappings = Object.values(forGroup);
      
      mappings.forEach((mapping) => {
        if (result.has(mapping._id)) return;
        result.add(mapping._id);
      });
    });

    return result;
  }

  const getMappingsWithValuesForYear = (year: Year) => {
    if (loadingMappingGroup) return [];

    const importsForYear = mappedResults?.[year]?.[mappingGroupId];

    if (!importsForYear) return [];

    return Object.values(importsForYear);
  }

  const getValueForMapping = (id: string, year: Year) => {
    if (loadingMappingGroup) return undefined;

    return mappedResults?.[year]?.[mappingGroupId]?.[id]
  }

  return {
    getMappingsWithValuesForYear,
    getAllMappingsWithValues,
    getValueForMapping
  }
}