import React from "react";
import Page from "../components/container/Page";
import { AppRoutes } from "../components/menu/Sidebar";
import DatevNumbersUpload from "../components/datev/DatevNumbersUpload";

export default function Dashboard() {
  return (
    <Page title={AppRoutes.Dashboard.label}>
      <DatevNumbersUpload />
    </Page>
  )
}