import React from "react";
import { BalanceIndicator } from "../../../types/mapping.schema";
import { Badge } from "@fluentui/react-components";

export default function BalanceTypeBadge(props: {
  type: BalanceIndicator,
  activeType?: BalanceIndicator,
  onClick?: () => void
}) {

  const {
    type,
    activeType,
    onClick
  } = props;

  const isActive = (!onClick && !activeType) || activeType === type;

  return (
    <Badge
      className="clickable"
      onClick={onClick}
      appearance={isActive ? "filled" : "outline"}
      color={isActive ? (type === BalanceIndicator.Credit ? "danger" : "brand") : "informative"}
    >
      {
        type === BalanceIndicator.Credit ? "Haben" : "Soll"
      }
    </Badge>
  )
}