import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMapping } from "../../types/mapping.schema";
import { ICollection } from "../../types/collection.types";

export const useMappingTemplates = () => {
  const { data, isLoading, mutate } = useSWR<ICollection<IMapping>>(Routes.mappings.templates);

  return {
    mappingTemplates: data?.items ?? [],
    mappingTemplatesById: data?.byId ?? {},
    loadingMappingTemplates: isLoading,
    reloadMappingTemplates: mutate
  }
}