import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IMappingGroupTree } from "../../types/mappingGroup.schema";

export const useMappingTree = (id: string) => {
  const { data, isLoading, mutate } = useSWR<IMappingGroupTree>(Routes.mappingGroups.tree.getValue([{ param: RouteParam.MappingGroup, value: id }]));

  return {
    mappingTree: data,
    loadingMappingTree: isLoading,
    reloadMappingTree: mutate
  }
}