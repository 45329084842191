import "./DatevNumbersUpload.css";
import { Avatar, Card, Divider, Text } from "@fluentui/react-components";
import { ArrowClockwiseRegular, CalculatorRegular } from "@fluentui/react-icons";
import React from "react";
import useNumberImport, { ExternalDataSource } from "../../../hooks/useNumberImport";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import { useAppSelector } from "../../../state/reduxHooks";
import CustomButton from "../button/CustomButton";
import Flex from "../container/Flex";
import CurrentGroupEntityTypeSelect from "../mappings/groups/CurrentGroupEntityTypeSelect";
import MappingGroupSelect from "../mappings/groups/MappingGroupSelect";
import ClearImportButton from "./ClearImportButton";
import MappedResultDialog from "./MappedResultDialog";
import UpdateDatevNumbersForm from "./UpdateDatevNumbersForm";

export default function DatevNumbersUpload() {

  const {
    value: currentGroupId
  } = usePersistance<string>(StorageKey.SelectedMappingGroup);

  const {
    loading,
    remapNumbers
  } = useNumberImport(ExternalDataSource.Datev, currentGroupId);

  const {
    isNarrower
  } = useResponsive(Breakpoint.Mobile);

  const {
    mappedResults
  } = useAppSelector(state => state.numbersImport);

  return (
    <Flex fullWidth gap={3}>
      <Flex row={!isNarrower} justify="between" fullWidth>
        <Text weight="bold">Aktiver Kontenrahmen</Text>
        <Flex row>
          <CurrentGroupEntityTypeSelect />
          <MappingGroupSelect className="full-width-mobile" />
        </Flex>
      </Flex>
      <Divider />
      <Card className="w-100">
        <Flex row gap={3}>
          <Avatar className="datev-numbers-upload-icon" icon={<CalculatorRegular />} size={64} shape="square" />
          <Flex row={!isNarrower} justify="between" fullWidth wrap>
            <Flex>
              <Text weight="bold" size={400}>Zahlenwerk</Text>
              <Text>Importierte Zahlen aus Schnittstellen verwalten</Text>
            </Flex>
            <UpdateDatevNumbersForm className="datev-upload-numbers-responsive-button" />
          </Flex>
        </Flex>
        <Divider />
        <Flex row justify="end" wrap fullWidth>
          {
            mappedResults && (
              <CustomButton
                className="datev-upload-numbers-responsive-button"
                text="Zahlen neu zuordnen"
                icon={<ArrowClockwiseRegular />}
                onClick={remapNumbers}
                appearance="secondary"
                loading={loading}
              />
            )
          }
          <ClearImportButton className="datev-upload-numbers-responsive-button" />
          <Flex row wrap className="full-width-mobile">
            {
              currentGroupId && !!mappedResults && <MappedResultDialog data={mappedResults} className="full-width-mobile" />
            }
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}