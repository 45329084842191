import { Button, Spinner, Text } from "@fluentui/react-components";
import React from "react";
import { AppColor, parseAppColor } from "../../../util/color";
import { DismissRegular, StopRegular } from "@fluentui/react-icons";
import Flex from "../container/Flex";

export type ButtonAppearance = "subtle" | "outline" | "secondary" | "primary" | "transparent";

export interface IButtonProps {
  text?: string,
  loading?: boolean,
  disabled?: boolean,
  icon?: React.ReactElement,
  className?: string,
  secondStepQuestion?: string,
  loadingText?: string,
  type?: "submit" | "reset" | "button",
  disabledText?: string,
  slot?: "before" | "after",
  color?: AppColor,
  size?: "small" | "medium" | "large",
  wrap?: boolean,
  appearance?: ButtonAppearance,
  onClick: (e?: any) => (Promise<any> | any),
}

export default function CustomButton(props: IButtonProps) {
  const {
    onClick,
    text,
    disabled,
    icon,
    color,
    className,
    wrap = true,
    type = "button",
    loadingText = "Bitte warten...",
    loading,
    appearance = "primary",
    size = "medium",
    disabledText,
    secondStepQuestion,
    slot = "before"
  } = props;

  const [hover, setHover] = React.useState<boolean>(false);
  const [secondStepActivated, setSecondStepActivated] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => setIsLoading(!!loading), [loading]);

  const handleClick = async (e) => {
    const clickProcessor = async () => {
      setIsLoading(true);
      await onClick(e);
      setIsLoading(false);
    }

    if (!secondStepQuestion) return await clickProcessor();
    if (secondStepActivated) {
      setSecondStepActivated(false);
      await clickProcessor();
    } else setSecondStepActivated(true);
  }

  const isDisabled = !!(disabled || isLoading || loading);
  const isReallyLoading = !!(isLoading || loading);

  const realIcon = (
    isReallyLoading
      ? <Spinner size="extra-tiny" />
      : icon
  )

  const isSecondStep = !!(secondStepQuestion && secondStepActivated);

  const realText = (
    !!text && (
      secondStepQuestion && secondStepActivated
        ? secondStepQuestion
        : (isReallyLoading ? loadingText : ((isDisabled && disabledText) || text))
    )
  )

  const button = (
    <Button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={(!isDisabled && color) ? parseAppColor(color, hover).getStyle() : undefined}
      className={className}
      onClick={handleClick}
      appearance={appearance}
      size={size}
      disabled={isDisabled}
      type={type}
      icon={realIcon}
      iconPosition={slot}
    >
      {
        realText && <Text wrap={wrap}>{realText}</Text>
      }
    </Button>
  )

  if (!isSecondStep) return button;

  return (
    <Flex row gap={1}>
      {button}
      <Button
        appearance="transparent"
        onClick={() => setSecondStepActivated(false)}
        icon={<DismissRegular />}
      />
    </Flex>
  )
}