import React from "react";
import { IMappingGroup, IMappingGroupWithMappings } from "../../../types/mappingGroup.schema";
import { useMappingForGroup } from "../../../state/mappings/useMappingForGroup";
import ModalForm from "../modal/ModalForm";
import useApi from "../../../api/useApi";
import { BalanceIndicator, CalculationResultType, IMapping, IMappingAccount, IMappingDocument } from "../../../types/mapping.schema";
import Flex from "../container/Flex";
import { Button, Card, Radio, RadioGroup, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import { Field, FieldArray } from "formik";
import { Add16Regular, AddRegular, CalculatorRegular, Delete16Regular, EditRegular } from "@fluentui/react-icons";
import FormikField from "../formik/FormikField";
import CustomButton from "../button/CustomButton";
import { useMappingGroups } from "../../../state/mappingGroups/useMappingGroups";
import { useUser } from "../../../state/user/useUser";
import IElementProps from "../../../types/element.types";
import useResponsive, { Breakpoint } from "../../../hooks/useResponsive";
import CheckBox from "../formik/CheckBox";
import UpdateCalculationStepForm from "./calculations/UpdateCalculationStepForm";
import CalculationResultTypeSelect from "./calculations/CalculationResultTypeSelect";
import BalanceIndicatorRadioGroup from "./BalanceIndicatorRadioGroup";
import { useMappingTemplates } from "../../../state/mappings/useMappingTemplates";
import EntityTypeSelect from "../entityTypes/EntityTypeSelect";

export interface IWithTemplateProps {
  isTemplateEditing?: boolean,
  isTemplateUsage?: boolean
}

export interface IUpdateMappingFormBase {
  customChangeHandler?: (mapping: IMappingDocument) => boolean,
  afterChange?: () => Promise<any>,
}

export interface IUpdateMappingFormProps extends IElementProps, IWithTemplateProps, IUpdateMappingFormBase {
  loading?: boolean,
  mappingGroup?: IMappingGroupWithMappings,
  mapping?: IMapping
}

export default function UpdateMappingForm(props: IUpdateMappingFormProps) {
  const {
    mappingGroup,
    isTemplateEditing,
    mapping,
    afterChange,
    loading,
    customChangeHandler,
    isTemplateUsage,
    className
  } = props;

  const {
    isNarrower
  } = useResponsive(Breakpoint.Mobile);

  const {
    mappingsUpdate,
    mappingsCreateTemplate,
    mappingsCreate
  } = useApi();

  const {
    user
  } = useUser();

  const {
    reloadMappingGroups
  } = useMappingGroups();

  const {
    reloadMappingTemplates
  } = useMappingTemplates();

  if (isTemplateEditing) {
    if (!user?.isGlobalAdmin) return null;
  }
  else {
    if (!mappingGroup) return null;
    if (mappingGroup.isGlobalPreset && !user?.isGlobalAdmin) return null;
  }

  const templateIsUsed = isTemplateUsage && !!(mapping.baseTemplate || mapping.isTemplate);
  const isCalculatedTemplate = !!(isTemplateUsage && mapping?.isCalculated);

  return (
    <ModalForm
      loading={loading}
      className={className}
      text={
        mapping
          ? (!isNarrower && (isCalculatedTemplate ? "Ansehen" : "Bearbeiten"))
          : "Neue Position"
      }
      title={
        mapping
          ? (isCalculatedTemplate ? "Berechnung ansehen" : `Position bearbeiten`)
          : "Neue Position erstellen"
      }
      description={(mappingGroup && !mapping) ? `Neue Position ${mappingGroup.name ? "für Kontenrahmen '" + mappingGroup.name + "' " : ""}erstellen` : ""}
      icon={
        mapping
          ? (isCalculatedTemplate ? <CalculatorRegular /> : <EditRegular />)
          : <AddRegular />
      }
      appearance={
        mapping
          ? (isCalculatedTemplate ? "primary" : "transparent")
          : undefined
      }
      size="large"
      initialValues={{
        accounts: mapping?.accounts ?? [],
        indicator: mapping?.indicator ?? BalanceIndicator.Credit,
        name: mapping?.name ?? "",
        shortName: mapping?.shortName ?? "",
        isTemplate: isTemplateEditing ?? false,
        baseTemplate: (mapping?.isTemplate ? mapping._id : mapping?.baseTemplate) ?? "",
        appliesToEntities: mapping?.appliesToEntities ?? [],
        calculationSteps: mapping?.calculationSteps ?? [],
        resultIsDeltaToLastYear: mapping?.resultIsDeltaToLastYear ?? false,
        resultType: mapping?.resultType ?? CalculationResultType.Currency,
        isCalculated: mapping?.isCalculated ?? false
      } as IMappingDocument}
      enableReinitialize
      onSubmit={async (values) => {

        let res: boolean = false;

        if (!!customChangeHandler) res = customChangeHandler(values);
        else res = (
          (mapping && (!mapping.isTemplate || isTemplateEditing))
            ? await mappingsUpdate(mapping._id, values)
            : (
              isTemplateEditing
                ? await mappingsCreateTemplate(values)
                : await mappingsCreate(mappingGroup._id, values)
            )
        ).success;

        if (!res) return false;

        if (isTemplateEditing) await reloadMappingTemplates();
        else await reloadMappingGroups();

        if (afterChange) await afterChange();

        return true;
      }}
    >
      {
        formik => (
          <Flex fullWidth>
            <Flex row fullWidth>
              <FormikField
                readOnly={templateIsUsed}
                className="w-75"
                key="mapping-name"
                name="name"
                label="Name der Position"
              />
              <FormikField
                readOnly={templateIsUsed}
                className="w-25"
                key="mapping-short-name"
                name="shortName"
                label="Abkürzung der Position"
              />
            </Flex>
            <Flex fullWidth gap={0}>
              <Text>Positionstyp</Text>
              <CheckBox
                readOnly={templateIsUsed}
                key="position-calculated-check"
                name="isCalculated"
                label="Berechnete Position"
                onChange={(e) => {
                  formik.setFieldValue("isCalculated", e.checked);
                  if (e.checked) formik.setFieldValue("accounts", []);
                  else formik.setFieldValue("calculationSteps", []);
                }}
              />
            </Flex>
            <EntityTypeSelect
              limitSelectionToAvailableIds={!isTemplateEditing && !!(mappingGroup?.appliesTo && mappingGroup.appliesTo.length)}
              availableIds={mappingGroup?.appliesTo}
              label="Firmentypen"
              description={
                !isTemplateEditing
                  ? "Hier können Sie diese Kontengruppe nur für bestimmte Firmengruppen freigeben, selbst wenn der Kontenrahmen auch für andere Firmengruppen gültig ist. Diese Position wird im Diagramm nicht angezeigt, sollte der Firmentyp des aktuellen Mandanten ein anderer sein."
                  : ""
              }
              onChange={(entityTypes) => formik.setFieldValue("appliesToEntities", entityTypes)}
              selectedEntityTypes={formik.values.appliesToEntities}
              readOnly={!isTemplateEditing && (!!mapping?.isTemplate || !!mapping?.baseTemplate)}
            />
            {
              (!formik.values.isCalculated || formik.values.resultType === CalculationResultType.Currency) && (
                <BalanceIndicatorRadioGroup
                  label="Bilanztyp / Ergebnistyp"
                  fieldName="indicator"
                  value={formik.values.indicator}
                  inline
                  readOnly={templateIsUsed}
                />
              )
            }
            {
              formik.values.isCalculated
                ? (
                  <Flex fullWidth>
                    <Card className="w-100" appearance="outline" style={{ overflow: "visible" }}>
                      <FieldArray name="calculationSteps">
                        {
                          (arrayHelpers) => (
                            <Flex fullWidth>
                              <Flex fullWidth row justify="between">
                                <Text>Berechnungskomponenten</Text>
                                {
                                  !templateIsUsed && (
                                    <UpdateCalculationStepForm
                                      isTemplateEditing={isTemplateEditing}
                                      mappingGroup={mappingGroup}
                                      addNewCalculationStep={(step) => arrayHelpers.push(step)}
                                    />
                                  )
                                }
                              </Flex>
                              <Flex row wrap>
                                {
                                  formik.values.calculationSteps && !!formik.values.calculationSteps.length
                                    ? formik.values.calculationSteps.map((step, index) => (
                                      <UpdateCalculationStepForm
                                        isTemplateEditing={isTemplateEditing}
                                        isTemplateUsage={templateIsUsed}
                                        mappingGroup={mappingGroup}
                                        calculcationStepIndex={index}
                                        removeElement={() => arrayHelpers.remove(index)}
                                        key={index}
                                        element={step}
                                      />
                                    ))
                                    : <Text>Keine Berechnungskomponenten</Text>
                                }

                              </Flex>
                            </Flex>
                          )
                        }
                      </FieldArray>
                    </Card>
                    <Flex fullWidth gap={0}>
                      <Text>Mehrjahresverrechnung</Text>
                      <CheckBox
                        name="resultIsDeltaToLastYear"
                        label="Das Ergebnis dieser Position ist die Differenz zwischen dem aktuellen und dem Vorjahreswert für diese Berechnung"
                        readOnly={templateIsUsed}
                      />
                      <Text size={200}>
                        Das Setzen dieser Option bewirkt, dass diese Kalkulation sowohl für das aktuelle als auch für das Vorjahr ausgewertet wird.
                        Als Wert / Ergebnis wird dann die Differenz dieser beiden angenommen.
                        Nützlich, um die Entwicklung von Positionen darzustellen, beispielsweise Umlaufvermögen oder Verbindlichkeiten.
                      </Text>
                    </Flex>
                    <Flex fullWidth gap={0}>
                      <CalculationResultTypeSelect
                        key="calculation-result-type"
                        onSave={t => formik.setFieldValue("resultType", t)}
                        readOnly={templateIsUsed}
                        value={formik.values.resultType}
                      />
                    </Flex>
                  </Flex>
                )
                : (
                  isTemplateEditing
                    ? null
                    : (
                      <Flex fullWidth>
                        <Card className="w-100" appearance="outline">

                          <FieldArray name="accounts">
                            {
                              (arrayHelpers) => (
                                <Flex fullWidth>
                                  <Flex fullWidth row justify="between">
                                    <Text>Konten</Text>
                                    <Button onClick={() => arrayHelpers.push({ accountFrom: 0, accountTo: 0 } as IMappingAccount)} icon={<Add16Regular />} appearance="transparent">Neues Konto</Button>
                                  </Flex>
                                  <Table>
                                    <TableHeader>
                                      <TableRow>
                                        <TableHeaderCell>Konto von</TableHeaderCell>
                                        <TableHeaderCell>Konto bis</TableHeaderCell>
                                        <TableHeaderCell>Aktionen</TableHeaderCell>
                                      </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                      {
                                        formik.values.accounts && !!formik.values.accounts.length
                                          ? formik.values.accounts.map((account, index) => (
                                            <TableRow key={index}>
                                              <TableCell>
                                                <FormikField
                                                  type="number"
                                                  placeholder="Konto eingeben"
                                                  appearance="filled-lighter"
                                                  name={`accounts.${index}.accountFrom`}
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <FormikField
                                                  type="number"
                                                  placeholder="Konto eingeben"
                                                  appearance="filled-lighter"
                                                  name={`accounts.${index}.accountTo`}
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <CustomButton color="danger" onClick={() => arrayHelpers.remove(index)} icon={<Delete16Regular />} appearance="transparent"></CustomButton>
                                              </TableCell>
                                            </TableRow>
                                          ))
                                          : <TableRow><TableCell colSpan={3}>Keine Konten</TableCell></TableRow>
                                      }
                                    </TableBody>
                                  </Table>
                                </Flex>
                              )
                            }
                          </FieldArray>
                        </Card>
                      </Flex>
                    )
                )
            }
          </Flex>
        )
      }
    </ModalForm>
  )
}