import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StorageKey } from "../hooks/usePersistance";
import { MappedObject } from "../types/collection.types";
import { StatusRegular } from "@fluentui/react-icons";

export const LocalSlice = createSlice({
  name: "local",
  initialState: {} as MappedObject<StorageKey, any>,
  reducers: {
    setLocalState: (state, action: PayloadAction<MappedObject<StorageKey, any>>) => {
      if (!action || !action.payload) return;

      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    }
  }
});

export const { setLocalState } = LocalSlice.actions;

export default LocalSlice.reducer;