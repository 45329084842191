import useSWR from "swr";
import { RouteParam, Routes } from "../../api/apiRoutes";
import { IChart } from "../../types/charts.schema";
import { ICollection } from "../../types/collection.types";

export const useCharts = () => {

  const { data, isLoading, mutate } = useSWR<ICollection<IChart>>(Routes.charts.all.route);

  return {
    charts: data?.items ?? [],
    chartsById: data?.byId ?? {},
    loadingCharts: isLoading,
    reloadCharts: mutate
  }
}