import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMappingGroup } from "../../types/mappingGroup.schema";
import { ILightweightMapping, IMapping, Year } from "../../types/mapping.schema";
import { IAccountValue } from "../../hooks/useNumberImport";

export interface IYearlyData<T> {
  year: Year,
  data: T
}

export interface IValueForYear {
  year: Year
}

export type YearlyValue<T> = { [key in Year]?: T };

export interface INumberImportForYear extends IValueForYear {
  values: Array<IAccountValue>
}

export type MappedNumberImport = { [key: string]: { [key: string]: IMappingValue } };

export interface IMappingValueBase {
  total: number,
  diffTotal: number,
  diffPercent: number,
}

export interface IMappingValue extends ILightweightMapping, IMappingValueBase {
  values: Array<IAccountValue>
}

export interface INumbersImportState {
  mappedResults?: YearlyValue<MappedNumberImport>;
  rawData?: YearlyValue<Array<IAccountValue>>;
  cacheLocally?: boolean;
  currentYear: number;
  hasDifferingAccountNumberLength: boolean;
  accountNumberLength?: number
}

export const NumbersImportSlice = createSlice({
  name: "numbersImport",
  initialState: {
    importedData: undefined,
    mappedResults: undefined,
    rawData: undefined,
    cacheLocally: false,
    hasDifferingAccountNumberLength: false,
    currentYear: new Date().getFullYear()
  } as INumbersImportState,
  reducers: {
    setCurrentYear: (state, action: PayloadAction<number>) => {
      state.currentYear = action.payload;
    },
    clearImportedData: (state, action: PayloadAction) => {
      try {
        state.mappedResults = undefined;
        state.rawData = undefined;
      }
      catch (e) { console.log(e); }
    },
    setMappedImport: (state, action: PayloadAction<YearlyValue<MappedNumberImport>>) => {
      const payload = action.payload;
      if (!payload) return;
      state.mappedResults = payload;
    },
    setMappedNumbers: (state, action: PayloadAction<IYearlyData<MappedNumberImport>>) => {
      const payload = action.payload;
      if (!payload) return;

      const {
        data,
        year
      } = payload;

      state.mappedResults[year] = data;
    },
    setRawData: (state, action: PayloadAction<IYearlyData<Array<IAccountValue>>>) => {
      const payload = action.payload;

      if (!payload) return;

      const {
        data,
        year
      } = payload;

      state.rawData[year] = data;
    },
    setCachedLocally: (state, action: PayloadAction<boolean>) => {
      state.cacheLocally = action.payload;
    },
    replaceImportedNumbers: (state, action: PayloadAction<INumbersImportState>) => {
      state.cacheLocally = action.payload.cacheLocally;
      state.currentYear = action.payload.currentYear;
      state.rawData = action.payload.rawData;
      state.mappedResults = action.payload.mappedResults;
    }
  }
});

export const {
  setCurrentYear,
  clearImportedData,
  setMappedNumbers,
  setRawData,
  setMappedImport,
  setCachedLocally,
  replaceImportedNumbers
} = NumbersImportSlice.actions;

export default NumbersImportSlice.reducer;