import { IMappingValue, IMappingValueBase } from "../state/datev/numbersImport.state";
import { CalculationResultType, IMapping, IMappingDocument } from "../types/mapping.schema";

export const formatTime = (d: any): string => {
  if (!d) return "";

  try {
    return new Date(d).toLocaleTimeString("DE-de", {
      timeStyle: "short"
    });
  }
  catch { return "" }
}

const appendZero = (str: any): string => {
  if (!str) return "00";
  return ('0' + str).slice(-2);
}

export const getMonthName = (month: number, locale: string = "de") => {
  try {
    const date = new Date(`1970-${month + 1}-01`);
    const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    return formatter.format(date);
  }
  catch (err) {
    console.log(err);
    return "";
  }
}

export const formatFileSize = (bytes: number, metricUnits: boolean = false, decimals: number = 1) => {
  const thresh = metricUnits ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) return bytes + ' B';

  const units = metricUnits
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** decimals;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(decimals) + ' ' + units[u];
}

export const formatDate = (d: any, withTime: boolean = false, withShortTime: boolean = false, hideDateIfToday: boolean = false): string => {
  if (!d) return "-";

  try {
    const date = new Date(d);

    if (isNaN(date.getDate())) return "-";
    if (!date) return "-";

    let result = `${appendZero(date.getDate())}.${appendZero(date.getMonth() + 1)}.${appendZero(date.getFullYear())}`;

    if (!withTime) return result;

    const time = appendZero(date.getHours()) + ":" + appendZero(date.getMinutes());

    if (date.getDate() === new Date().getDate() && hideDateIfToday) return time;

    result += ` ${time}`;

    if (withShortTime) return result;

    result += `:${appendZero(date.getSeconds())}.${('00' + date.getMilliseconds()).slice(-3)}h`;

    return result;
  }
  catch {
    return "-"
  }
}

export const formatDateGmailStyle = (d: any, long: boolean = false): string => {
  if (!d) return "-";

  try {
    const date = new Date(d);

    if (isNaN(date.getDate())) return "-";
    if (!date) return "-";

    const time = appendZero(date.getHours()) + ":" + appendZero(date.getMinutes());

    if (date.getDate() === new Date().getDate()) return time;

    const dateString = `${appendZero(date.getDate())}${long ? "." : ""} ${date.toLocaleDateString("de-DE", { month: long ? "long" : "short" })}`;

    if (date.getFullYear() === new Date().getFullYear()) return dateString;

    return date.toLocaleDateString("de-DE", { month: "2-digit", year: "2-digit", day: "2-digit" });
  }
  catch {
    return "-";
  }
}

export const formatMailAddress = (name?: any, mail?: any) => {
  if (name) return `${name} <${mail}>`;
  return mail;
}

export const getMappingValue = (m: IMappingValue) => {
  switch (m.resultType) {
    case CalculationResultType.Currency:
      return m.resultIsDeltaToLastYear ? m.diffTotal : m.total;
    case CalculationResultType.Number:
      return m.resultIsDeltaToLastYear ? m.diffTotal : m.total;
    case CalculationResultType.Percentage:
      return m.resultIsDeltaToLastYear ? m.diffPercent : m.total;
  }
}

export const getFormattedMappingValue = (value: number, type: CalculationResultType, opts: IFormatOptions = { currency: "EUR", appendix: "EUR", withFraction: true }) => {
  switch (type) {
    case CalculationResultType.Currency:
      return formatCurrency(value, opts);
    case CalculationResultType.Number:
      return formatNumber(value, opts);
    case CalculationResultType.Percentage:
      return formatPercent(value, opts);
  }
}

export const formatMappingValue = (m: IMappingValue, opts: IFormatOptions = { currency: "EUR", appendix: "EUR" }) => {
  const value = getMappingValue(m);
  return getFormattedMappingValue(value, m.resultType, opts);
}

export const formatPercent = (n: any, opts: IFormatOptions = { withFraction: false }): string => {
  try {
    return `${formatNumber(n * 100, opts)} %`
  }
  catch { }

  return "- %";
}

export const formatNumber = (n: any, opts: IFormatOptions = { withFraction: false }): string => {
  try {
    const {
      logScale,
      withFraction
    } = opts;

    let input = n;

    if (typeof n !== "number") input = parseFloat(n);
    if (logScale) return logNumberScale(n, withFraction);

    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: withFraction ? 2 : 0,
      minimumFractionDigits: withFraction ? 2 : 0
    }).format(input);
  }
  catch {

  }

  return n;
}

export interface IFormatOptions {
  currency?: "EUR",
  appendix?: "EUR" | "€",
  isAccountingView?: boolean,
  withFraction?: boolean,
  logScale?: boolean
}

const logNumberScale = (num: number, withFraction: boolean = false): string => {
  const lookup = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;

  const valueLookup = Math.abs(num);
  const item = lookup.find(item => valueLookup >= item.value);

  if (!item) return num.toString();

  return (num / item.value).toFixed(withFraction ? 2 : 0).replace(regexp, "").concat(item.symbol);
}

export const formatCurrency = (n: any, opts: IFormatOptions = { currency: "EUR", appendix: "EUR" }): string => {
  try {
    if (typeof n !== "number") return n;

    const {
      appendix = "EUR",
      currency = "EUR",
      logScale,
      isAccountingView = false,
      withFraction = true
    } = opts;

    if (logScale) return `${logNumberScale(n, withFraction)} ${appendix}`;

    const inputValue = isAccountingView ? Math.abs(n) : n;

    const result = new Intl.NumberFormat("de-DE", {
      currency: currency,
      maximumFractionDigits: withFraction ? 2 : 0,
      minimumFractionDigits: withFraction ? 2 : 0
    }).format(inputValue);

    if (isAccountingView) {
      if (n < 0) return `H ${result} ${appendix}`;
      else return `S ${result} ${appendix}`;
    }

    return `${result} ${appendix}`;
  }
  catch { }

  return n;
}


export const formatDuration = (duration: number): string => {
  try {
    const numToString = (n: any): string => n.toString().padStart(2, '0');

    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    if (hours > 24) {
      const days = Math.floor(hours / 24);
      return `${numToString(days)}d ${numToString(hours % 24)}h ${numToString(minutes)}min`;
    }

    return `${numToString(hours)}h ${numToString(minutes)}min`;
  }
  catch { }

  return duration.toString();
}