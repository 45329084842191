import { Button, Table, TableBody, TableCell, TableCellActions, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Text } from "@fluentui/react-components";
import { ArrowCircleDownSplitRegular, BuildingBankRegular, CalculatorRegular, ColorRegular, EyeRegular } from "@fluentui/react-icons";
import React from "react";
import usePersistance, { StorageKey } from "../../../hooks/usePersistance";
import useYearUtil from "../../../hooks/useYearUtil";
import { IMappingValue, MappedNumberImport, YearlyValue } from "../../../state/datev/numbersImport.state";
import IElementProps from "../../../types/element.types";
import { BalanceIndicator } from "../../../types/mapping.schema";
import { formatCurrency, formatMappingValue } from "../../../util/formatter";
import Flex from "../container/Flex";
import PaginatedList from "../list/PaginatedList";
import { MappingListTab } from "../mappings/MappingList";
import ModalDialog from "../modal/ModalDialog";

export interface IMappedResultDialogProps extends IElementProps {
  loading?: boolean,
  data: YearlyValue<MappedNumberImport>
}

export default function MappedResultDialog({ loading, data, className }: IMappedResultDialogProps) {

  const {
    value: currentGroupId
  } = usePersistance<string>(StorageKey.SelectedMappingGroup);

  const {
    fancyYears
  } = useYearUtil();

  const getItems = () => {
    var allYears = Object.values(data ?? {});

    var result = new Map<string, IMappingValue>();

    allYears.forEach(yearValues => {
      var groupValues = yearValues[currentGroupId];
      if (!groupValues) return;
      var mappings = Object.values(groupValues);
      if (!mappings) return;
      mappings.forEach(mapping => {
        if (!mapping) return;
        result.set(mapping._id, mapping);
      });
    });

    return Array.from(result.values());
  }

  const items = getItems();

  return (
    <ModalDialog
      className={className}
      text={"Zuordnungen"}
      icon={<EyeRegular />}
      size="large"
      priority={10}
      disabled={loading}
      loading={loading}
      appearance="outline"
      title="Zuordnungen und Werte"
    >
      <PaginatedList
        tabs={MappingListTab}
        getTabIcon={t => {
          switch (t) {
            case "Templates": return <ColorRegular />;
            case "Calculated": return <CalculatorRegular />;
            case "NotCalculated": return <BuildingBankRegular />;
            case "ParentGroup": return <ArrowCircleDownSplitRegular />;
            default: return null;
          }
        }}
        getTabLabel={t => {
          switch (t) {
            case "Templates": return "Vorlagen";
            case "Calculated": return "Berechnete Positionen";
            case "NotCalculated": return "Kontengruppen";
            case "ParentGroup": return "Mutter-Kontenrahmen"
            default: return "Alle";
          }
        }}
        getTabVisible={t => {
          switch (t) {
            case "ParentGroup": return false;
          }

          return true;
        }}
        noItemsPlaceholder="Keine passenden Konten gefunden. Bitte überprüfen Sie Ihre Zuordnungen für die gewählte Zuordnungsgruppe und den Import."
        headers={["Zuordnungsgruppe", "Typ", ...fancyYears.map(f => f.label), "Aktionen"]}
        getKey={r => r._id}
        pageSize={10}
        filterPlaceholder="Nach Kontengruppen suchen..."
        itemMatchesFilter={(f, i) => {
          const name = i.name?.toLowerCase();
          return name?.includes(f.toLowerCase());
        }}
        itemMatchesTab={(tab, m) => {
          if (tab === "Calculated") return m.isCalculated;
          if (tab === "NotCalculated") return !m.isCalculated;
          if (tab === "Templates") return m.isTemplate || !!m.baseTemplate;
          return true;
        }}
        items={items}
        renderItem={(r: IMappingValue) => {
          return [
            <TableCell>
              <TableCellLayout>
                <Text>
                  {r.name}
                </Text>
              </TableCellLayout>
            </TableCell>,
            <TableCell>
              <Text>{r.indicator === BalanceIndicator.Credit ? "H" : "S"}</Text>
            </TableCell>,
            ...fancyYears.map(f => {
              const getValue = () => {
                const yearValues = data[f.year];
                if (!yearValues) return "-";
                const groupValues = yearValues[currentGroupId];
                if (!groupValues) return "-";
                const mapping = groupValues[r._id];
                if (!mapping) return "-";
                return formatMappingValue(mapping);
              }
              return (
                <TableCell>
                  <Text>{getValue()}</Text>
                </TableCell>
              )
            }),
            <TableCellActions>
              <ModalDialog
                button={onClick => <Button appearance="transparent" icon={<EyeRegular />} onClick={onClick} >Konten sehen</Button>}
                text="Zuordnungen ansehen"
                title="Konten und Werte"
              >
                <Text weight="bold">{r.name}</Text>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Konto</TableHeaderCell>
                      <TableHeaderCell>EB</TableHeaderCell>
                      <TableHeaderCell>JVZ S / H</TableHeaderCell>
                      <TableHeaderCell>SB</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {
                      r.values.map((v, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <TableCellLayout
                              truncate
                              description={v.accountName}
                            >
                              {v.accountNumber}
                            </TableCellLayout>
                          </TableCell>
                          <TableCell>
                            <Text size={200}>{formatCurrency(v.startDebit - v.startCredit)}</Text>
                          </TableCell>
                          <TableCell>
                            <Flex gap={0}>
                              <Text size={200}>{formatCurrency(v.movementDebit)}</Text>
                              <Text size={200}>{formatCurrency(v.movementCredit)}</Text>
                            </Flex>
                          </TableCell>
                          <TableCell>
                            <Text size={200}>{formatCurrency(v.endDebit - v.endCredit)}</Text>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </ModalDialog>
            </TableCellActions>,
          ]
        }}
      />
    </ModalDialog>
  )

}